import { Trans } from "react-i18next";
import styled from "styled-components";
import React, { useState, useEffect } from "react";

interface Option {
  key: number;
  optionName: string;
  sfName: string;
  sfFee: number;
  notice?: string;
}

interface RadioListProps {
  optionList: Option[];
  name?: string;
  spanColor?: string;
  width?: string;
  hideIndex?: number;
  onSelect?: any;
}
const RadioList: React.FC<RadioListProps> = (props: RadioListProps) => {
  const [checkIndex, setCheckIndex] = useState(0);

  useEffect(() => {
    setCheckIndex(0);
  }, [props.optionList]);

  const localSelect = (o) => {
    setCheckIndex(props.optionList.indexOf(o));
    props.onSelect(o);
  };

  return (
    <Layout width={props.width ? props.width : "100%"}>
      {props.optionList.map((o, i) => (
        <RadioBox isShow={true} key={i}>
          <Radio>
            <input
              type="radio"
              name={props.name ? props.name : ""}
              value={o.key}
              checked={props.optionList.indexOf(o) === checkIndex}
              onChange={() => localSelect(o)}
            />
            &nbsp;&nbsp;<Trans>{o.optionName}</Trans>
          </Radio>
          <Notification spanColor={props.spanColor ? props.spanColor : "red"}>
            <span>
              <Trans>{o.notice}</Trans>
            </span>
          </Notification>
        </RadioBox>
      ))}
    </Layout>
  );
};

const Layout = styled.div<{ width?: string }>`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
`;

const RadioBox = styled.div<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? "flex" : "none")};
  justify-content: space-between;
  & ~ & {
    margin-top: 10px;
  }

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;

const Radio = styled.div`
  flex: 8;
  @media (max-width: 360px) {
    font-size: 15px;
  }
`;

const Notification = styled.div<{ spanColor?: string }>`
  flex: 4;
  span {
    text-align: left;
    color: ${(props) => props.spanColor};
  }
  @media (max-width: 460px) {
    margin-left: 1rem;
  }
`;

export default RadioList;
