import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface GoogleAutoCompleteAddressProps {
  autoCompleteType: string;
  onAutoCompleteAddress: Function;
  country: string;
}

const GoogleAutoCompleteAddress: React.FC<GoogleAutoCompleteAddressProps> = (props: GoogleAutoCompleteAddressProps) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const { t } = useTranslation();

  let autoComplete;
  const initAutocomplete = (updateQuery, autoCompleteRef, onAutoCompleteAddress) => {
    // @ts-ignore
    if (!window.google) {
      return;
    }
    let obj;
    // @ts-ignore
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, obj);
    if (props.country) {
      autoComplete.setComponentRestrictions({ country: [props.country] });
    }
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery, onAutoCompleteAddress));
  }

  const handlePlaceSelect = (updateQuery, onAutoCompleteAddress) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    // return obj can have different lengths
    // this logic has NOT been accounted for yet
    let addressComponents = addressObject.address_components;
    let streetAddress = `${findAddressNameByType(addressComponents, "street_number", false)} ${findAddressNameByType(addressComponents, "route", false)}`;
    let city = findAddressNameByType(addressComponents, "locality", false);
    if (city === "") {
      city = findAddressNameByType(addressComponents, "neighborhood", false);
    }

    let stateProvinceShortName = findAddressNameByType(addressComponents, "administrative_area_level_1", false);
    let stateProvinceLongName = findAddressNameByType(addressComponents, "administrative_area_level_1", true);
    let countryShortName = findAddressNameByType(addressComponents, "country", false);
    let countryLongName = findAddressNameByType(addressComponents, "country", true);
    let zipPostal = findAddressNameByType(addressComponents, "postal_code", false);

    let addressArray = [streetAddress, city, stateProvinceShortName, countryShortName, zipPostal, countryLongName, stateProvinceLongName];
    onAutoCompleteAddress(addressArray);
  }

  const findAddressNameByType = (addressComponents, componentType, isLongName) => {
    for (let i = 0; i < addressComponents.length; i++) {
      let item = addressComponents[i];
      if (item.types.includes(componentType) && isLongName) {
        return item.long_name;
      } else if (item.types.includes(componentType) && !isLongName) {
        return item.short_name;
      }
    }
    return "";
  };

  useEffect(() => {
    initAutocomplete(setQuery, autoCompleteRef, props.onAutoCompleteAddress);
  }, [props.onAutoCompleteAddress]);

  return (
    <div className="search-location-input">
      <Form.Control
        type="text"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={props.autoCompleteType === "city" ? t("Enter a city") : t("Enter your address")}
        value={query} />

    </div>
  );
}

export default GoogleAutoCompleteAddress;
