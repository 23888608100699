import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface AppYesNoProps {
  label: string;
  name: string;
  onChange: Function;
  required: boolean;
  defaultYes?: boolean;
}

const AppYesNo: React.FC<AppYesNoProps> = (props: AppYesNoProps) => {
  return (
    <>
      <Label>
        <Trans>{props.label}</Trans>
        {props.required && <RequiredLabel> *</RequiredLabel>}
      </Label>
      <Radio type="radio" name={props.name} value="true" onClick={() => { props.onChange(true); }} defaultChecked={props.defaultYes ? true : false} />
      <Text className="d-inline-block mx-2">
        <Trans>Yes</Trans>
      </Text>
      <Radio type="radio" style={{ marginLeft: "50px" }} name={props.name} value="false" onClick={() => { props.onChange(false); }} defaultChecked={!props.defaultYes ? true : false} />
      <Text className="d-inline-block mx-2 mb-4">
        <Trans>No</Trans>
      </Text>
    </>
  );
}

const RequiredLabel = styled.span`
  color: #e32
`;

const Label = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 500;
  @media (max-width: 418px) {
    font-size: 14px;
  }
`;

const Radio = styled.input`
  border: 0px;
  width: 18px;
  height: 18px;
  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-size: 16px;
  color: black;
  font-weight: 500;
  width: max-content;
  span {
    color: red;
  }
  @media (max-width: 456px) {
    font-size: 17px;
  }
`;

export default AppYesNo;