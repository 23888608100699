import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { Trans } from "react-i18next"

interface MobilePaymentStepperProps {
  step1: string;
  step2: string;
  step3: string;
}

const MobilePaymentStepper: React.FC<MobilePaymentStepperProps> = (props: MobilePaymentStepperProps) => {
  const inProgressImg = '/images/inProgressStep.png';
  const completeImage = '/images/completeStep.png';
  const initialImg = '/images/initialStep.png';
  return (
    <Container>
      <Steps>
        <Div>
          <Image
            src={props.step1 === "In Progress" ? inProgressImg : completeImage}
            width="45px"
            height="45px"
          />
          <Hr color={props.step1 === "In Progress" ? "#0494CA" : "#00FAFF"} />
        </Div>
        <Div>
          <Step opacity="30%">Step 1</Step>
        </Div>
        <Div>
          <Step style={{ width: "7rem", height: "50px" }}>
            <Trans>Personal Information</Trans>
          </Step>
        </Div>
        <Div>
          <Status color={props.step1 === "In Progress" ? "#0494ca" : "#00FAFF"}>
            {props.step1}
          </Status>
        </Div>
      </Steps>
      <Steps initial={props.step2 === "pending" ? true : false}>
        <Div>
          <Image
            src={
              props.step2 === "pending"
                ? initialImg
                : props.step2 === "In Progress"
                  ? inProgressImg
                  : completeImage
            }
            width="45px"
            height="45px"
          />
          <Hr color={props.step2 === "In Progress" ? "#0494CA" : "#00FAFF"} />
        </Div>
        <Div>
          <Step opacity="30%">Step 2</Step>
        </Div>
        <Div>
          <Step style={{ width: "7rem", height: "50px" }}>
            <Trans>Summary</Trans>
          </Step>
        </Div>
        <Div>
          <Status
            color={
              props.step2 === "pending"
                ? null
                : props.step2 === "In Progress"
                  ? "#0494ca"
                  : "#00FAFF"
            }
          >
            {props.step2}
          </Status>
        </Div>
      </Steps>
      <Steps initial={props.step3 === "pending" ? true : false}>
        <Div>
          <Image
            src={
              props.step3 === "pending"
                ? initialImg
                : props.step3 === "In Progress"
                  ? inProgressImg
                  : completeImage
            }
            width="45px"
            height="45px"
          />
        </Div>
        <Div>
          <Step opacity="30%">Step 3</Step>
        </Div>
        <Div>
          <Step style={{ width: "7rem", height: "50px" }}>Payment</Step>
        </Div>
        <Div>
          <Status
            color={
              props.step3 === "pending"
                ? null
                : props.step3 === "In Progress"
                  ? "#0494ca"
                  : "#00FAFF"
            }
          >
            {props.step3}
          </Status>
        </Div>
      </Steps>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Steps = styled.div<{ initial?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ initial }) => (initial ? "black" : null)};
  opacity: ${({ initial }) => (initial ? "30%" : null)};
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Step = styled.span<{ opacity?: string }>`
  color: black;
  font-size: 16px;
  font-weight: 600;
  opacity: ${({ opacity }) => opacity || "100%"};

  @media (max-width: 460px) {
    font-size: 14px;
  }
`;

const Hr = styled.hr<{ opacity?: string }>`
  margin: 0 0.7rem;
  width: 5rem;
  font-weight: 500;
  color: ${({ color }) => color};
  padding: 1px;
  border-radius: 5px;
  opacity: ${({ opacity }) => opacity || "30%"};

  @media (max-width: 460px) {
    margin: 0 0.3rem;
    width: 4rem;
  }

  @media (max-width: 460px) {
    margin: 0 0.3rem;
    width: 3rem;
  }
  @media (max-width: 360px) {
    margin-left: 0 0.3rem;
    width: 2rem;
  }
`;

const Status = styled.div`
  padding: 2px 10px;
  color: ${({ color }) => color};
  background-color: rgb(246 251 252);
  border-radius: 25px;
  font-size: 14px;
`;

export default MobilePaymentStepper;