import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { api } from "../utilities/api";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateShopObject } from "../redux/actions/shopActions";
import { RootState } from "../redux/reducers/rootReducer";

interface CountriesDropDownListProps {
  defaultValue: string;
  onSelect: Function;
  onlyUSA?: boolean; 
}

const CountriesDropDownList: React.FC<CountriesDropDownListProps> = (props: CountriesDropDownListProps) => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const isUSA = (name) => name === "USA" ? "United States" : name;
  const [countryObjects, setCountryObjects] = useState(shopDetails.countries);
  const [country, setCountry] = useState(isUSA(props.defaultValue));
  const [search, setSearch] = useState('');
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDropdownHeight(window.innerHeight - 350);

      const handleWindowResize = () => {
        if (window.innerWidth < 769) {
          setDropdownHeight(window.innerHeight - 500);
        } else {
          setDropdownHeight(window.innerHeight - 350);
        }
      };

      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);

      return () => window.removeEventListener("resize", handleWindowResize);
    }
  }, []);
  
  useEffect(() => {
    setCountry(isUSA(props.defaultValue));
  }, [props.defaultValue]);

  const optionSelect = (name) => {
    const countryName = isUSA(name);
    const countryIndex = countryObjects.find((c) => c.CountryName === countryName)?.CountryCode;
    const countryCodeIso = countryObjects.find((c) => c.CountryName === countryName)?.CountryCodeISO;
    props.onSelect({ countryName, countryIndex, countryCodeIso });
    setCountry(countryName);
  };

  const getFlagEmoji = (countryCode) => {
    if (countryCode) {
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt());
      return String.fromCodePoint(...codePoints);
    } else {
      return "";
    }
  }

  const onClick = () => {
    if (search) {
      setSearch('');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="w-100" disabled={props.onlyUSA}>
        {country}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{zIndex: 1}}>
        <InputGroup className="px-2">
          <Form.Control placeholder="Search Country..." type="text" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
          <Button onClick={onClick}>
            <FontAwesomeIcon icon={search ? faClose : faSearch} />
          </Button>
        </InputGroup>
        <Dropdown.Divider />
        <div style={{ maxHeight: `${dropdownHeight}px`, overflow: 'auto', width: "100%" }}>
          {countryObjects.filter(s => s.CountryName.toLowerCase().includes(search.toLowerCase())).map((c, i) => (
            <Dropdown.Item key={i} onClick={() => optionSelect(c.CountryName)} style={{ display: "flex", flexDirection: "row" }}>
              {/* <div style={{ paddingRight: "8px" }}>{getFlagEmoji(c.CountryCodeISO)}</div> */}
              <Trans>{c.CountryName}</Trans>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CountriesDropDownList;