import styled from "styled-components";

export const UnderlineTitleBlack = styled.p`
  font-size: 22px;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 5px;
  margin-left: 0.5rem;
  position: relative;

  :before {
    content: "";
    position: absolute;
    width: 50%;
    height: 2px;
    bottom: 0;
    border-bottom: 1px solid black;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const UnderlineTitleBlue = styled.p`
  color: #0594ca;
  font-weight: 500;
  font-size: 20px;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;

  :before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    border-bottom: 1px solid #0594ca;
  }
`;
